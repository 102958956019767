import request from '@/utils/request'

//修改个人信息
export function edituserinfo (data) {
  return request({
    url: '/common/user/edit',
    method: 'post',
    data
  })
}

//修改密码
export function edituserresetpassword (data) {
  return request({
    url: '/common/auth/reset-password',
    method: 'post',
    data
  })
}

// 跳过修改密码
export function useredit (data) {
  return request({
    url: '/common/user/edit',
    method: 'post',
    data
  })
}

//更换手机号
export function changeusermobile (data) {
  return request({
    url: '/common/user/change-mobile',
    method: 'post',
    data
  })
}

//获取用户信息
export function getuserinfo (params) {
  return request({
    url: '/common/user/get-info',
    method: 'GET',
    params
  })
}

//发送短信验证码
export function sendsmscode (data) {
  return request({
    url: '/common/sms/send-code',
    method: 'post',
    data
  })
}

//注册
export function registerUaserName (data) {
  return request({
    url: '/common/auth/register',
    method: 'post',
    data
  })
}

// 刷新token
export function refreshToken (params) {
  return request({
    url: '/common/auth/refresh-token',
    method: 'get',
    params
  })
}

//发送邮箱验证码
export function sendemailcode (data) {
  return request({
    url: '/common/email/send-code',
    method: 'post',
    data
  })
}

//忘记密码
export function forgetpw (data) {
  return request({
    url: '/common/auth/forget',
    method: 'post',
    data
  })
}

//修改校外邮箱
export function edituserEmail (data) {
  return request({
    url: '/common/user/change-email',
    method: 'post',
    data
  })
}

// 获取网站友情链接
export function getLinkList (params) {
  return request({
    url: '/portal/links/get-list',
    method: 'get',
    params
  })
}
import Cookies from 'js-cookie'

// 基础登录Token
const TokenKey = 'WebSite-Token'; // 登录Token
const tokenExpTime = 'tokenExpTime' // Token过期时间
const currentLoginTime = 'currentLoginTime' // 当前登录时间
// 身份携带
const IdentifierKey = 'Identifier-Token'
// 用户信息携带
const WebNameKey = 'webName' // 名字
const WebAvaterKey = 'webAvater' // 头像
// 加密携带
const SignKey = 'sign'
const RedirectTypeKey = 'redirectType'
// 特殊接口携带
const csrf_tokenKey = 'csrf_token'

// 登录存储Token
export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}
// 保存Token过期时间
export function getTokenExpTime() {
  return Cookies.get(tokenExpTime)
}
export function setTokenExpTime(time) {
  return Cookies.set(tokenExpTime, time)
}
export function removeTokenExpTime() {
  return Cookies.remove(tokenExpTime)
}
// 保存登录时的时间
export function getCurrentLoginTime() {
  return Cookies.get(currentLoginTime)
}
export function setCurrentLoginTime(time) {
  return Cookies.set(currentLoginTime, time)
}
export function removeCurrentLoginTime() {
  return Cookies.remove(currentLoginTime)
}
//用户身份信息，1：学生，2：老师，3：校外人员，4：管理员，单个直接取值放到header里面，多个需要弹窗选择身份
export function getIdentifierToken() {
  return Cookies.get(IdentifierKey)
}
export function setIdentifierToken(token) {
  return Cookies.set(IdentifierKey, token)
}
export function removeIdentifierToken() {
  return Cookies.remove(IdentifierKey)
}
//用户名字
export function getWebNameToken() {
  return Cookies.get(WebNameKey)
}
export function setWebNameToken(name) {
  return Cookies.set(WebNameKey, name)
}
export function removeWebNameToken() {
  return Cookies.remove(WebNameKey)
}
//用户头像
export function getWebAvaterToken() {
  return Cookies.get(WebAvaterKey)
}
export function setWebAvaterToken(avater) {
  return Cookies.set(WebAvaterKey, avater)
}
export function removeWebAvaterToken() {
  return Cookies.remove(WebAvaterKey)
}
// 加密
export function getSignToken() {
  return Cookies.get(SignKey)
}
export function setSignToken(Sign) {
  return Cookies.set(SignKey, Sign)
}
export function removeSignToken() {
  return Cookies.remove(SignKey)
}
export function getRedirecttypeToken() {
  return Cookies.get(RedirectTypeKey)
}
export function setRedirecttypeToken(Redirecttype) {
  return Cookies.set(RedirectTypeKey, Redirecttype)
}
export function removeRedirecttypeToken() {
  return Cookies.remove(RedirectTypeKey)
}
// 特殊接口需要带token
export function getcsrf_tokenKey() {
  return Cookies.get(csrf_tokenKey)
}
export function setcsrf_tokenKey(csrf_token) {
  return Cookies.set(csrf_tokenKey, csrf_token)
}
export function removecsrf_tokenKey() {
  return Cookies.remove(csrf_tokenKey)
}
import request from '@/utils/request'

//获取广告列表
export function getBannerList (params) {
  return request({
    url: '/portal/banner/get-list',
    method: 'GET',
    params
  })
}

//获取详情
export function getSiteInfo (params) {
  return request({
    url: '/common/site-config/get-info',
    method: 'GET',
    params
  })
}